import AOS from "aos";
import React from "react";
// contexts.
import { PopupProvider } from "@/contexts/PopUp";
import { PageProvider } from "@/contexts/Page";

import "aos/dist/aos.css";
import "./src/static/styles/app.scss";

export const onClientEntry = () => {
  AOS.init({
    duration: 1200,
  });
};

export const onRouteUpdate = () => {
  AOS.refresh();
};

export const wrapRootElement = ({ element }) => (
  <PageProvider>
    <PopupProvider>{element}</PopupProvider>
  </PageProvider>
);
